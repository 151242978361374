<template>
  <section class="HowItWorks">
    <h2 class="HowItWorks--Title">
      HelloAsso,<br />
      <span>
        {{ $t('howItWorks.title') }}
      </span>
    </h2>
    <div class="Columns">
      <div class="Column">
        <div class="HowItWorks--Section HowItWorks--Section-Users">
          <h3 subheading class="HowItWorks--SectionHeading">
            {{ $t('howItWorks.users') }}
          </h3>
          <h4 class="h5 HowItWorks--SectionTitle">
            {{ $t('howItWorks.discoverTogether') }}
          </h4>
          <ul class="HowItWorks--List">
            <li class="HowItWorks--ListItem">
              <span class="HowItWorks--ListItemIcon">
                <HaIcon
                  :icon="faLocationDot"
                  fixed-width
                />
              </span>
              <p>
                <strong>
                  {{
                    $t('howItWorks.features.user.findOrganizations_1')
                  }}
                </strong>
                {{
                  $t('howItWorks.features.user.findOrganizations_2')
                }}
              </p>
            </li>
            <li class="HowItWorks--ListItem">
              <span class="HowItWorks--ListItemIcon">
                <HaIcon
                  :icon="faMagnifyingGlass"
                  fixed-width
                />
              </span>
              <p>
                <strong>
                  {{ $t('howItWorks.features.user.findActivites_1') }}
                </strong>
                {{ $t('howItWorks.features.user.findActivites_2') }}
              </p>
            </li>
            <li class="HowItWorks--ListItem">
              <span class="HowItWorks--ListItemIcon">
                <HaIcon
                  :icon="faShieldHalved"
                  fixed-width
                />
              </span>
              <p>
                {{ $t('howItWorks.features.user.toParticipate_1') }}
                <strong>
                  {{ $t('howItWorks.features.user.toParticipate_2') }}
                </strong>
              </p>
            </li>
          </ul>
          <div class="ButtonGroup ButtonGroup-Column">
            <ha-button
              href="/e/recherche"
              data-ux="Explore_LP_HowItWorks_FindActivities"
            >
              {{ $t('howItWorks.findNewActivities') }}
            </ha-button>
            <SeoLink
              :href="`${configEnv.NUXT_ENV_BASE_URL}/utilisateur/historique`"
              data-ux="Explore_LP_HowItWorks_PreviousPayments"
              :text="$t('howItWorks.findMyPayments')"
            />
          </div>
        </div>
      </div>
      <div class="Column">
        <div
          class="HowItWorks--Section HowItWorks--Section-Associations"
        >
          <h3 subheading class="HowItWorks--SectionHeading">
            {{ $t('howItWorks.organizations') }}
          </h3>
          <h4 class="h5 HowItWorks--SectionTitle">
            {{ $t('howItWorks.handleActivities') }}
          </h4>
          <ul class="HowItWorks--List">
            <li class="HowItWorks--ListItem">
              <span class="HowItWorks--ListItemIcon">
                <HaIcon
                  :icon="faBullhorn"
                  fixed-width
                />
              </span>
              <p>
                <strong>
                  {{
                    $t(
                      'howItWorks.features.organization.launchYourProjects_1'
                    )
                  }}
                </strong>
                {{
                  $t(
                    'howItWorks.features.organization.launchYourProjects_2'
                  )
                }}
              </p>
            </li>
            <li class="HowItWorks--ListItem">
              <span class="HowItWorks--ListItemIcon">
                <HaIcon
                  :icon="faPiggyBank"
                  fixed-width
                />
              </span>
              <p>
                <strong>
                  {{
                    $t(
                      'howItWorks.features.organization.getPayments_1'
                    )
                  }}
                </strong>
                {{
                  $t('howItWorks.features.organization.getPayments_2')
                }}
              </p>
            </li>
            <li class="HowItWorks--ListItem">
              <span class="HowItWorks--ListItemIcon">
                <HaIcon
                  :icon="faHeadset"
                  fixed-width
                />
              </span>
              <p>
                <strong>
                  {{
                    $t('howItWorks.features.organization.getHelp_1')
                  }}
                </strong>
                {{ $t('howItWorks.features.organization.getHelp_2') }}
              </p>
            </li>
          </ul>
          <div class="ButtonGroup ButtonGroup-Column">
            <ha-button
              :href="`${configEnv.NUXT_ENV_BASE_URL}/associations?from=explore`"
              color="basic"
              data-ux="Explore_LP_HowItWorks_SignUp"
            >
              {{ $t('howItWorks.signUp') }}
            </ha-button>
            <SeoLink
              :href="`${configEnv.NUXT_ENV_BASE_URL}/utilisateur/redirection-backoffice`"
              data-ux="Explore_LP_HowItWorks_SignIn"
              :text="$t('howItWorks.signIn')"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {
  faLocationDot,
  faMagnifyingGlass,
  faShieldHalved,
  faBullhorn,
  faPiggyBank,
  faHeadset
} from '@fortawesome/pro-solid-svg-icons'
import { HaButton, HaIcon } from '#components'
import SeoLink from '@/components/SeoLink/SeoLink.vue'
import type { ContentBlock } from '~/domains/thematic/interfaces/content.interface'
import useConfigEnv from '@/composables/useConfigEnv'
import { useI18n } from '#imports'

const props = defineProps<{
  block?: ContentBlock
}>()

const i18n = useI18n()

const configEnv = useConfigEnv()
</script>

<style scoped lang="scss">
.HowItWorks {
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    max-width: 900px;
  }

  @include mediaQuery(600, 'between', 900) {
    gap: 1em;

    .Columns {
      gap: 1em;
    }
  }

  @include mediaQuery(800) {
    .Columns {
      display: flex;
    }
  }

  .Columns {
    @include mediaQuery(900) {
      gap: 2rem;
    }

    .Column {
      padding-right: calc(var(--gutter) / 2);
    }
  }

  &--Title {
    margin-bottom: 0;
    line-height: $ha-line-height-small;
    width: 100%;
  }

  &--Section {
    margin-top: $ha-spacing-jumbo;

    @include mediaQuery(900) {
      margin-top: $ha-spacing-big;
    }

    &Heading {
      display: block;
      margin-bottom: $ha-spacing-small;
      font-size: 1rem;
      letter-spacing: .1em;
      font-weight: 400;
    }

    &Title {
      margin-bottom: $ha-spacing-large;
      line-height: $ha-line-height-small;
      font-size: 1.4em;
    }
  }

  &--List {
    padding: 0;
    list-style: none;

    &Item {
      display: flex;
      align-items: center;
      margin-bottom: $ha-spacing-medium;

      &:last-of-type {
        margin-bottom: $ha-spacing-big;
      }

      &Icon {
        flex: 0 0 ($ha-unit * 9); // 72px
        color: var(--ha-color-primary);
        font-size: $ha-unit * 3; // 24px
        text-align: center;
      }

      p {
        flex-basis: auto;
        margin: 0;
      }

      strong {
        font-style: normal;
      }
    }
  }

  .ButtonGroup {
    display: flex;
    flex-wrap: wrap;
    margin: -$ha-spacing-tiny;

    &-Column {
      flex-direction: column;
      align-items: flex-start;
    }

    > * {
      margin: $ha-spacing-tiny;
    }

    .HaButton-Primary {
      background-color: var(--hads-color-iris) !important;
    }
  }
}
</style>
